import { Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import type { PayableInvoiceGroup } from '@orus.eu/backend/src/invoicing/views/invoice-grouping-view'
import { ContentContainerBackoffice, spacing } from '@orus.eu/pharaoh'
import { Header } from '@orus.eu/pharaoh/src/patterns/header'
import { memo } from 'react'
import { trpcReact } from '../../../../client'
import { formatDateTime } from '../../../../lib/format'
import { TableCellAmount } from '../../../molecules/table-elements/table-cell-amount'
import { TableCellHeader } from '../../../molecules/table-elements/table-cell-header'
import { TableCellText } from '../../../molecules/table-elements/table-cell-text'

export const PlatformNegativeInvoiceGroupsList = memo(function PlatformNegativeInvoiceGroupsList() {
  const [invoiceGroups] = trpcReact.invoices.listNegativeInvoiceGroups.useSuspenseQuery()

  return (
    <ContentContainerBackoffice marginTop={spacing[70]}>
      <Header title="Liste des factures groupées négatives" />

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCellHeader>Id de la facture</TableCellHeader>
              <TableCellHeader>Due le</TableCellHeader>

              <TableCellHeader>Montant</TableCellHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoiceGroups.map((invoiceGroup, index) => (
              <NegativeInvoiceGroupsList key={index} invoiceGroup={invoiceGroup} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ContentContainerBackoffice>
  )
})

function NegativeInvoiceGroupsList({
  invoiceGroup,
}: {
  invoiceGroup: { invoiceGroup: PayableInvoiceGroup; mainInvoiceDueTimestamp: number }
}): JSX.Element {
  const { total, mainInvoiceId } = invoiceGroup.invoiceGroup

  return (
    <TableRow key={mainInvoiceId}>
      <TableCellText text={mainInvoiceId} />
      <TableCellText text={formatDateTime(invoiceGroup.mainInvoiceDueTimestamp)} />
      <TableCellAmount amount={total} />
    </TableRow>
  )
}
